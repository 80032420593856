import React from "react";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import {Text} from 'rebass';

/**
 * Render default response for 404
 */
class NotFound extends React.Component {
    public render() {
        return (
            <Layout>
                <SEO/>
                <Section>
                    <div style={{marginTop: "100px"}}>
                        <Headings.h1>{'Content Not Found'}</Headings.h1>
                        <Text>{"It might've been moved or updated. Got a question? <a href='mailto:consulting@quant.one'>Send an Email</a>"}</Text>
                    </div>
                </Section>
            </Layout>
        );
    }
}

export default NotFound;
